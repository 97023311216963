
































































































































































































































































































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import * as actionTypes from "@/store/action-types";
import IReportFilter from '@/interfaces/models/reportFilter.interface';
import MobileSubFilterPopup from "@/components/reports/MobileSubFilterPopup.vue";
import {Getter} from "vuex-class";
import ITag from '@/interfaces/models/tag.interface';
import IIdentifier from '@/interfaces/models/identifier.interface';

@Component({
  components: {
    MobileSubFilterPopup
  }
})
export default class MobileFilterPopup extends Vue {
  @Prop({type: Boolean}) isVisible!: boolean;
  @Prop({type: Object, required: true}) searchFilter!: IReportFilter;
  @Prop({type: Array, required: true}) customSearchFilter!: any[];
  @Prop({type: Boolean, required: true}) existCustomFields!: Boolean;

  showSubFilterPopup: boolean = false
  @Getter tags!: ITag[];
  @Getter identifiers!: IIdentifier[];

  async mounted(): Promise<void> {
    await this.$store.dispatch(actionTypes.FETCH_TAGS);
  }

  beforeDestroy(): void {
    document.removeEventListener('keydown', this.handleEsc);
  }

  async cancel(): Promise<void> {
    this.$emit('close-mobile-filter-dialog');
  }

  handleEsc(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.cancel();
    }
  }
  
  handlecancel(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.cancel();
    }
  }

  toggleSubFilter(filterType: any) {
    if(filterType.indexOf('customField_') === -1) {
      const sf: any = this.searchFilter
      sf[filterType].show = true
    }else{
      const cs = filterType.split('customField_')[1]
      const cSF = this.customSearchFilter.find( k => k.key === cs)
      cSF.show = !cSF.show
    }
  }

  @Watch('isVisible')
  watchIsVisible() {
    if (this.isVisible) {
      this.$store.dispatch(actionTypes.FETCH_TAGS);
      document.addEventListener('keyup', this.handlecancel);
    } else {
      document.removeEventListener('keyup', this.handlecancel);
    }
  }

  toggleMobileFilterPopup(filterType: string): void {
    if(filterType.indexOf('customField_') === -1) {
      const sf: any = this.searchFilter
      sf[filterType].show = !sf[filterType].show
    }else{
      const cs = filterType.split('customField_')[1]
      const cSF = this.customSearchFilter.find( k => k.key === cs)
      cSF.show = !cSF.show
    }
  }

  updateFilterKeywords(param: Object): void {
    const pm: any = param
    if(pm.filterType.indexOf('customField_') === -1) {
      const sf: any = this.searchFilter
      sf[pm.filterType].show = false
      sf[pm.filterType].keywords = pm.keywords
      this.$emit('update-filter', sf)
    }else{
      const cs = pm.filterType.split('customField_')[1]
      const tmp = [...this.customSearchFilter]
      const cSF = tmp.find( k => k.key === cs)
      cSF.show = false
      cSF.keywords = pm.keywords
      this.$emit('update-custom-filter', tmp)
    }
  }
}
