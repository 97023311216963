











import {Component, Vue} from 'vue-property-decorator';
import {AgentsService} from "@/http/services/agents.service";
import {VerifyToneType} from "@/interfaces/requests/IVerifyToken";

@Component
export default class VerifyToken extends Vue {
  async created() {
    const token = this.$route.query.token as string;
    const type = this.$route.query.type as VerifyToneType;

    if (!token || !type) {
      return await this.$router.push({
        path: '/sign-in',
        query: {
          message: 'Token invalid'
        }
      });
    }

    try {
      const response = await AgentsService.verifyToken({token, type});
      await this.$router.push({
        path: '/change-password',
        query: {
          message: type === VerifyToneType.NEW_REGISTRATION ? 'Your account successfully activated' : 'Change your password',
          email: response.email,
          agentAccountId: `${response.id}`,
        },
      })
    } catch (e) {
      await this.$router.push({
        path: '/sign-in',
        query: {
          message: e.message,
        }
      });
    }
  }
}
