
















































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Getter} from "vuex-class";
import ITag from "@/interfaces/models/tag.interface";
import * as actionTypes from "@/store/action-types";
import IContact from "@/interfaces/models/contact.interface";
import {ContactsService} from "@/http/services/contacts.service";
import {Helpers} from "@/common/utils/helpers.js";

interface TagSelectCondition {
  id: string;
  name: string;
  icon: string;
  label: string;
}

@Component
export default class SearchPopup extends Vue {
  tagSelectConditions: TagSelectCondition[] = [
    {id: 'and', name: 'AND', label: 'AND', icon: 'mdi-plus'},
    {id: 'or', name: 'OR', label: 'OR', icon: 'mdi-plus'},
    {id: 'andBranch', name: 'AND', label: 'AND (...)', icon: 'mdi-source-branch'},
    {id: 'orBranch', name: 'OR', label: 'OR (...)', icon: 'mdi-source-branch'},
  ];

  @Prop({type: Boolean}) isVisible!: boolean;
  currentTabIndex: number = 0;

  // Contact search related variables
  availableContacts: IContact[] = [];
  contactSearchDebounceTimer: number = 0;
  isLoadingContacts: boolean = false;
  searchContactText: string = '';

  // Tag search related
  @Getter tags!: ITag[];
  tagSearchLabel: string = 'Search tags';
  searchTagValue: string = '';
  tagQuery: string[] = [];
  selectedTagQuery: any[] = []
  availableTagItems: any[] = [];
  isSelectConditionMode: boolean = false;
  isTagsCorrected: boolean = false;
  isNeedCloseParenthesis: boolean = false;
  branchCondition: TagSelectCondition | null = null;

  isLoading: boolean = false;

  async mounted(): Promise<void> {
    await this.$store.dispatch(actionTypes.FETCH_TAGS);
    document.addEventListener('keydown', this.handleEsc);
  }

  beforeDestroy(): void {
    document.removeEventListener('keydown', this.handleEsc);
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  get showResultsBtn(): boolean {
    if (this.currentTabIndex === 0) {
      return !!this.searchContactText.trim().length;
    } else {
      return this.isSelectConditionMode && !!this.selectedTagQuery.length;
    }
  }

  async cancel(): Promise<void> {
    this.clearSearch();
    this.$emit('close-dialog');
  }

  handleEsc(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.cancel();
    }
  }

  clearSearch(): void {
    if (!this.isVisible) {
      return;
    }

    this.searchContactText = '';
    this.availableContacts = [];
    this.selectedTagQuery = [];
    this.tagQuery = [];
  }

  // Contact search related methods
  searchContacts(searchValue: string): void {
    clearTimeout(this.contactSearchDebounceTimer);

    this.contactSearchDebounceTimer = setTimeout(async () => {
      this.isLoadingContacts = true;
      this.availableContacts = await ContactsService.fetchContacts({name: searchValue});
      this.isLoadingContacts = false;
    }, 1000);
  }

  getProfilePhoto(contact: IContact): string | boolean {
    return Helpers.getProfilePhoto(contact);
  }

  getInitials(contact: IContact): string {
    return Helpers.getInitials(contact);
  }

  getContactInfo(contact: IContact): string {
    const name = contact.firstName && contact.lastName ? `${contact.firstName} ${contact.lastName}` : '';
    return `${name} (${Helpers.formatNumber(contact.phoneNumber)})`;
  }

  // Tag search related methods
  @Watch('selectedTagQuery')
  observeSelectedTags(): void {
    if (this.selectedTagQuery.length === 0) {
      this.isSelectConditionMode = false;
    }

    if (!this.isTagsCorrected) {
      this.selectedTagQuery = this.selectedTagQuery.filter(tag => typeof tag !== 'string');
      this.isTagsCorrected = true
    } else {
      this.isTagsCorrected = false;
    }
  }

  @Watch('isVisible')
  watchIsVisible() {
    if (this.isVisible) {
      this.$store.dispatch(actionTypes.FETCH_TAGS);
      document.addEventListener('keyup', this.handleCloseDialog);
    } else {
      document.removeEventListener('keyup', this.handleCloseDialog);
    }
  }

  handleCloseDialog(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.cancel();
    }
  }

  async processSearch(): Promise<void> {
    this.isLoading = true;
    if (this.currentTabIndex === 1) {
      const queryForBackend = this.tagQuery.join(' ');
      await this.$store.dispatch(actionTypes.FETCH_CONTACTS, {
        tags: queryForBackend
      })
    } else {
      await this.$store.dispatch(actionTypes.FETCH_CONTACTS, {
        name: this.searchContactText
      });
    }
    this.$emit('close-dialog', this.currentTabIndex === 1
      ? this.selectedTagQuery.map(({name}) => name).join(' ')
      : this.searchContactText);
    this.isLoading = false;
    this.clearSearch();
  }

  handleTagChange(value: string): void {
    if (!value) {
      this.availableTagItems = [...this.tags];
    } else {
      this.availableTagItems = this.tags
        .filter(({name}: ITag) => name.toLowerCase().includes(value.toLowerCase()));
    }
  }

  addTag(tag: ITag): void {
    if (this.branchCondition) {
      if (this.isNeedCloseParenthesis) {
        this.selectedTagQuery = this.selectedTagQuery.concat({...tag, name: `${tag.name})`});
        this.tagQuery = this.tagQuery.concat(`${tag.id})`);
        this.isNeedCloseParenthesis = false;
        this.branchCondition = null;
        this.isSelectConditionMode = true;
      } else {
        this.selectedTagQuery = this.selectedTagQuery.concat({...tag, name: `(${tag.name}`}, this.branchCondition);
        this.tagQuery = this.tagQuery.concat(`(${tag.id} ${this.branchCondition.name.toUpperCase()}`)
        this.isNeedCloseParenthesis = true;
      }
    } else {
      this.selectedTagQuery = this.selectedTagQuery.concat(tag);
      this.searchTagValue = '';
      this.tagQuery = this.tagQuery.concat(`${tag.id}`);
      this.isSelectConditionMode = true;
    }
  }

  selectCondition(condition: TagSelectCondition): void {
    if (condition.id.includes('Branch')) {
      this.selectedTagQuery = condition.id === 'andBranch'
        ? this.selectedTagQuery.concat(this.tagSelectConditions[0])
        : this.selectedTagQuery.concat(this.tagSelectConditions[1]);
      this.branchCondition = condition;
    } else {
      this.selectedTagQuery = this.selectedTagQuery.concat(condition);
    }
    this.tagQuery = this.tagQuery.concat(`${condition.name}`);
    this.isSelectConditionMode = false;
  }

  clearTagSearch(): void {
    this.selectedTagQuery = [];
    this.tagQuery = [];
  }
}
