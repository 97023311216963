


















































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as actionTypes from "@/store/action-types";
import IContact from "@/interfaces/models/contact.interface";
import ITag from "@/interfaces/models/tag.interface";

interface TagSelectCondition {
  id: string;
  name: string;
  icon: string;
  label: string;
}

@Component
export default class FilterPopup extends Vue {
  @Prop({ type: Boolean }) isVisible!: Boolean;
  @Prop({ type: String, default: "firstName" }) filterType!: string;
  @Prop({ type: Array, default: [] }) keywords!: Array<string>;
  @Prop({ type: Array, default: () => [] }) tags!: ITag[];
  currentTabIndex: number = 0;

  // validation
  rules: { required: Function } = {
    required: (v: any) => !!v || "this field is required"
  };

  // Contact search related variables
  availableContacts: IContact[] = [];
  contactSearchDebounceTimer: number = 0;
  isLoadingContacts: boolean = false;
  searchContactText: string = "";

  // Keyword area related variables
  filters: Array<string> = [""];
  filter_phoneNumber: string = "";
  filter_isSubscribed: string = "";
  formValidation: Boolean = false;

  isLoading: boolean = false;

  mounted(): void {
    if (this.keywords.length > 0) {
      this.keywords.forEach(val => {
        if (val !== "") this.filters.push(val);
      });
    } else {
      if (this.filters.length === 0) {
        this.filters.push("");
      }
    }
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  @Watch("keywords")
  onKeywordChanged(value: Array<string>, oldValue: Array<string>) {
    this.filters = [];

    if (value.length > 0) {
      value.forEach(val => {
        if (val !== "") this.filters.push(val);
      });
    } else {
      this.filter_phoneNumber = "";
      this.filter_isSubscribed = "all";
      if (this.filters.length === 0) {
        this.filters.push("");
      }
    }
  }

  async cancel(): Promise<void> {
    this.$emit("close-filter-dialog", this.filterType);
  }

  handleEsc(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.cancel();
    }
  }

  addFilter() {
    this.filters.push("");
  }

  clearFilter(index: any) {
    this.filters.splice(index, 1);
    if (this.filters.length === 0) {
      this.filters.push("");
    }
  }

  @Watch("isVisible")
  watchIsVisible() {
    if (this.isVisible) {
      this.$store.dispatch(actionTypes.FETCH_TAGS);
      document.addEventListener("keyup", this.handleCloseDialog);
      this.isLoading = false;
      if (!["tags", "isSubscribed"].includes(this.filterType)) {
        setTimeout(() => {
          window.document.getElementById("filter_0")!.focus();
        }, 200);
      }
    } else {
      document.removeEventListener("keyup", this.handleCloseDialog);
    }
  }

  handleCloseDialog(event: KeyboardEvent): void {
    const e: any = event;
    if (event.key === "Escape") {
      this.cancel();
    } else if (event.key === "Enter") {
      if (e.target.nodeName === "INPUT") {
        this.onFilterUpdate();
      }
    }
  }

  // phoneNumber
  rulePhoneNumber(val: string): boolean {
    if (!val) return false;
    if (val === "" || val.length !== 12) {
      return false;
    } else {
      var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      return phoneNumberPattern.test(val);
    }
  }

  onFilterUpdate(): void {
    let filteredKeywords = this.filters.filter(f => f !== "" && f !== null);

    if (this.filterType === "phoneNumber") {
      if (this.rulePhoneNumber(this.filter_phoneNumber)) {
        filteredKeywords = [this.filter_phoneNumber];
        this.formValidation = false;
      } else {
        filteredKeywords = [];
      }
    } else if (this.filterType === "isSubscribed" && this.filter_isSubscribed) {
      if (this.filter_isSubscribed === "all") {
        filteredKeywords = [];
      } else {
        filteredKeywords = [this.filter_isSubscribed];
      }
    }

    this.isLoading = true;
    this.$emit("update-filter-sub", {
      filterType: this.filterType,
      keywords: filteredKeywords
    });
  }

  isTagSelected(t: string): boolean {
    const res = this.keywords.includes(t);
    return this.keywords.includes(t);
  }

  selectTag(value: boolean, tag: string): void {
    this.filters = this.filters.filter(k => k !== tag);
    if (value) {
      this.filters.push(tag);
    }
  }
}
