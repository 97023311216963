



























































































import {Component, Vue, Watch} from 'vue-property-decorator';
import ReportsTable from "@/components/reports/ReportsTable.vue";
import {Getter} from "vuex-class";
import IReport from "@/interfaces/models/report.interface";
import * as actionTypes from "@/store/action-types";
import MobileFilterPopup from "@/components/reports/MobileFilterPopup.vue";
import NavigationDrawer from "@/components/header/NavigationDrawer.vue";
import {NotificationType} from "@/interfaces/models/INotification";
import IReportFilter from '@/interfaces/models/reportFilter.interface';
import ITag from '@/interfaces/models/tag.interface';
import { ReportsService } from '@/http/services/reports.service';
import Pusher from "pusher-js";
import {ENV} from "@/common/utils/env";
import { saveAs } from 'file-saver';

@Component({
  components: {
    NavigationDrawer,
    MobileFilterPopup,
    ReportsTable
  }
})
export default class Reports extends Vue {
  @Getter reports!: IReport[];
  @Getter newReport!: boolean;
  @Getter reports_ct!: number;
  @Getter tags!: ITag[];
  @Getter identifiers!: ITag[];

  pusher: any;
  selectedReports: number[] = [];
  fab: boolean = false;
  showAddTagDialog: boolean = false;
  showRemoveTagDialog: boolean = false;
  showRemoveReportDialog: boolean = false;
  showCompose: boolean = false;
  selectedComposeReports: number[] = [];
  showImportPopup: boolean = false;
  showSearchPopup: boolean = false;
  showMobileFilterPopup: boolean = false;
  isLoading: boolean = false;
  isClearFilter: boolean = false;
  isSelectAll: boolean = false;
  searchParam: any = {};

  /* filter variable */
  searchFilter: IReportFilter = {
    'fromNumber': {show: false, sort: 0, keywords: []},
    'toNumber': {show: false, sort: 0, keywords: []},
    'status': {show: false, sort: 0, keywords: []},
    'createdAt': {show: false, sort: 0, keywords: []},
    'identifier': {show: false, sort: 0, keywords: []},
    'carrier': {show: false, sort: 0, keywords: []},
    'segmentCount': {show: false, sort: 0, keywords: []},
    'tags': {show: false, sort: 0, keywords: []},
  };
  customSearchFilter: any[] = []
  existCustomFields: boolean = false
  sortedReports: IReport[] = [];
  totalReportPages: number = 0;
  curPage: number = 1;
  perPage: number = 10;
  reports_count: number = 0;

  async mounted(): Promise<void> {
    await Promise.all([
      this.$store.dispatch(actionTypes.FETCH_IDENTIFIER),
      this.$store.dispatch(actionTypes.FETCH_TAGS),
      this.$store.dispatch(actionTypes.FETCH_REPORTS)
    ]);
    this.initPusher();
  }

  beforeUnmount(): void {
    this.pusher.unsubscribe('textodog-message');
  }

  initPusher() : void {
    this.pusher = new Pusher(ENV.PUSHER_APP_KEY, {
      cluster: 'us2'
    });
    const channel = this.pusher.subscribe('textodog-message');
    channel.bind('messageReceived', (data: any) => {
      this.curPage = 1;
      this.getSearchReport();
    });
    channel.bind('messageStatusUpdated', (data: any) => {
      // conversationId, messageId, status
      const isExisting = this.sortedReports.findIndex( (iM: IReport) => iM.conversationId === data.conversationId);
      if(isExisting !== -1) {
        const sRs = [...this.sortedReports];
        sRs[isExisting].status = data.status;
        this.sortedReports = [...sRs];
      }
    });
  }

  @Watch('newReport', {immediate: true})
  getNewReport(): void {
    if(this.newReport){
      this.curPage = 1;
      this.getSearchReport();
    }else{
      console.log("new Report false")
    }
  }

  @Watch('reports', {immediate: true})
  setPageHeader(): void {
    this.reports_count = Number(this.reports_ct);
    this.totalReportPages = Math.ceil(this.reports_count/this.perPage);
    this.searchFilter = {
      'fromNumber': {show: false, sort: 0, keywords: []},
      'toNumber': {show: false, sort: 0, keywords: []},
      'status': {show: false, sort: 0, keywords: []},
      'createdAt': {show: false, sort: 0, keywords: []},
      'identifier': {show: false, sort: 0, keywords: []},
      'carrier': {show: false, sort: 0, keywords: []},
      'segmentCount': {show: false, sort: 0, keywords: []},
      'tags': {show: false, sort: 0, keywords: []},
    };
    let t_customSearchFilter: any[] = []
    if( this.reports.length > 0 ) {
      const cFlds = this.reports.findIndex( k => k.customFields.length > 0)
      if(cFlds !== -1) {
        this.existCustomFields = true
        for (let index = 0; index < this.reports[cFlds].customFields.length; index++) {
          const element: any = this.reports[cFlds].customFields[index];
          t_customSearchFilter.push({show: false, index, id: element.id, key: element.name, keywords: []})
        }
      }
    }
    this.customSearchFilter = t_customSearchFilter
    this.sortedReports = [...this.reports]
    this.$store.dispatch('header/updateHeaderTitle', `${this.reports.length} Reports`);
  }

  @Watch('reports_ct')
  getWatchReportCt(): void {
    this.reports_count = Number(this.reports_ct);
  }

  selectAll(state: boolean) : void {
    this.isSelectAll = state;
  }

  modifyDate(str: string) : string {
    const str_arr = str.split("-");
    return str_arr[2] + "-" + str_arr[0] + "-" + str_arr[1]
  }

  async getSearchReport(): Promise<void> {
    this.searchParam = {}
    let filter_param: any = {}
    let orderBy = ""
    let direction = ""
    const sf: any = this.searchFilter
    for (const key in this.searchFilter) {
      const pm: any = key
      if(sf[pm].sort !== 0) {
        orderBy = pm
        direction = sf[pm].sort === -1 ? 'desc' : 'asc'
      }
      if(key === 'tags') {
        let filter_tags: any[] = []
        this.tags.forEach(tag => {
          if(sf[pm].keywords.includes(tag.name)) filter_tags.push(tag.id)
        });
        if( filter_tags.length > 0 )
          filter_param['tagIds'] = filter_tags.join()
      } else if(key === 'identifier') {
        let filter_identifiers: any[] = []
        this.identifiers.forEach(idf => {
          if(sf[pm].keywords.includes(idf.name)) filter_identifiers.push(idf.id)
        });
        if( filter_identifiers.length > 0 )
          filter_param['messageIdentifierIds'] = filter_identifiers.join()
      } else {
        if(sf[pm].keywords.length > 0){
          if(key === 'createdAt'){
            if(sf[pm].keywords[0] !== '') filter_param['fromDate'] = this.modifyDate(sf[pm].keywords[0])
            if(sf[pm].keywords[1] !== '') filter_param['toDate'] = this.modifyDate(sf[pm].keywords[1])
          } else if(key === 'status'){
            if(sf[pm].keywords[0] !== '') {
              filter_param['statuses'] = sf[pm].keywords.join().toLowerCase()
              if(sf[pm].keywords.includes('pending')) filter_param['statuses'] = filter_param['statuses'] + ",sent"
            }
          } else {
            if(sf[pm].keywords[0] !== '') filter_param[pm] = sf[pm].keywords.join()
          }
        }
      }
    }
    // pagination
    filter_param['currentPage'] = this.curPage;
    filter_param['perPage'] = this.perPage;

    // forReport=true
    filter_param['forReport'] = "true";

    let filter_cf: any[] = []
    for (let index = 0; index < this.customSearchFilter.length; index++) {
      const element = this.customSearchFilter[index];
      if(element.keywords.length > 0) {
        if(element.keywords[0] !== '') filter_cf.push({id: element.id,  value: element.keywords.join()})
      }
    }
    if(filter_cf.length > 0) filter_param.customFields = JSON.stringify(filter_cf)
    if(orderBy !== '') {
      filter_param.orderBy = orderBy
      filter_param.direction = direction
    }

    this.searchParam = filter_param
    try {
      this.isLoading = true;
      const res_reports = await ReportsService.filterReports(filter_param);
      this.onHideFilter();
      this.sortedReports = res_reports.data;
      this.reports_count = Number(res_reports.total);
      this.totalReportPages = Math.ceil(this.reports_count/this.perPage);
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false;
    }

  }

  @Watch('selectedReports', {immediate: true})
  observeCountOfSelected(): void {
    this.$store.dispatch('header/updateCountOfSelected', this.selectedReports.length);
  }

  beforeDestroy(): void {
    this.$store.dispatch('header/updateCountOfSelected', 0);
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  async closeAddTag(): Promise<void> {
    await this.$nextTick();
    this.showAddTagDialog = false;
  }

  async addTag(tag: string): Promise<void> {
    try {
      this.isLoading = true;
      await this.$store.dispatch(actionTypes.ADD_TAG_FOR_MULTIPLE_REPORTS, {
        reportIds: this.selectedReports,
        tag
      });
      this.showAddTagDialog = false;
      await this.$store.dispatch(actionTypes.FETCH_TAGS);
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR,
      });
    } finally {
      this.isLoading = false;
    }
  }

  async applyTagRemoving(tag: string): Promise<void> {
    try {
      this.isLoading = true;
      await this.$store.dispatch(actionTypes.REMOVE_TAG_FOR_MULTIPLE_REPORTS, {
        reportIds: this.selectedReports,
        tag
      });
      this.showRemoveTagDialog = false;
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR,
      });
    } finally {
      this.isLoading = false;
    }
  }

  importReports(): void {
    this.showImportPopup = true;
  }

  createReport(): void {
    this.$store.dispatch(actionTypes.OPEN_DRAWER_TO_CREATE);
  }

  async onClearFilter(): Promise<void> {
    this.existCustomFields = false
    const sf: any = this.searchFilter
    for (const key in this.searchFilter) {
      const pm: any = key
      sf[pm].show = false
      sf[pm].sort = 0
      sf[pm].keywords =  []
    }
    if(this.reports.length > 0) {
      this.existCustomFields = true
      for (let index = 0; index < this.customSearchFilter.length; index++) {
        const element = this.customSearchFilter[index];
        element.show = false
        element.sort = 0
        element.keywords = []
      }
    }
    // this.sortedReports = [...this.reports]
    this.curPage = 1;
    this.getSearchReport()
  }

  onHideFilter(): void {
    const sf: any = this.searchFilter
    for (const key in this.searchFilter) {
      const pm: any = key
      sf[pm].show = false
    }
    if(this.reports.length > 0) {
      for (let index = 0; index < this.customSearchFilter.length; index++) {
        const element = this.customSearchFilter[index];
        element.show = false
      }
    }
  }

  resetSort(): void {
    const sf: any = this.searchFilter
    for (const key in this.searchFilter) {
      const pm: any = key
      sf[pm].sort = 0
    }
  }

  updateSort(param: String): void {
    this.resetSort()
    const sf: any = this.searchFilter
    const pm: any = param
    sf[pm.sortType].sort = pm.sort
    this.curPage = 1;
    this.getSearchReport()
  }
  updateCustomSort(param: Object): void {
    const pm: any = param
    this.customSearchFilter = pm
    this.curPage = 1;
    this.getSortReport()
  }
  getSortReport() : void {
    let sC: any[] = this.sortedReports
    const sf: any = this.searchFilter
    for (const key in this.searchFilter) {
      const pm: any = key
      if(sf[pm].sort === 1) { // Ascending
        sC.sort((a,b) => a[pm] < b[pm] ? -1 : a[pm] > b[pm] ? 1 : 0)
      }else if(sf[pm].sort === -1) { // Descending
        sC.sort((a,b) =>  a[pm] < b[pm] ? 1 : a[pm] > b[pm] ? -1 : 0)
      }
    }
    this.sortedReports = sC
  }
  getValidString(key: any, idx: number) : string {
    if(key?.customFields) {
      if(key?.customFields[idx].value) {
        return key?.customFields[idx].value
      }
      else {
        return ''
      }
    }else {
      return ''
    }
  }

  updateFilter(param: Object): void {
    const pm: any = param
    this.searchFilter = pm;
    this.curPage = 1;
    this.getSearchReport();
  }
  updateCustomFilter(param: Object): void {
    const pm: any = param
    this.customSearchFilter = pm
    this.curPage = 1;
    this.getSearchReport();
  }
  updateExistCustomField(param: boolean): void {
    this.existCustomFields = param;
  }
  updatePagination(param: number) {
    this.curPage = param
    this.getSearchReport();
  }

  composeMessages(): void {
    this.selectedComposeReports = [...this.selectedReports];
    this.showCompose = true;
  }

  async exportCSV(exportContact: boolean): Promise<void> {
    let csvParam = {...this.searchParam}
    csvParam['messageIds'] = this.selectedReports
    csvParam['exportContact'] = exportContact
    csvParam['selectedAll'] = this.isSelectAll
    if(csvParam['statuses']) csvParam['statuses'] = csvParam['statuses'].split(",")
    delete csvParam['forReport']
    delete csvParam['perPage']
    delete csvParam['currentPage']
    await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
      text: 'CSV file will be sent shortly.',
      type: NotificationType.SUCCESS
    });
    try {
      this.isLoading = true;
      await ReportsService.exportReports(csvParam);
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false;
    }
  }

  sendToOneReport(reportId: number): void {
    this.selectedComposeReports = [reportId];
    this.showCompose = true;
  }

  showAddTagPopup(): void {
    this.showAddTagDialog = true;
  }

  removeTag(): void {
    this.showRemoveTagDialog = true;
  }

  toggleMobileFilterPopup(value: boolean): void {
    this.showMobileFilterPopup = !this.showMobileFilterPopup;
  }

  onClickPlus() : void {
    this.$store.dispatch(actionTypes.OPEN_DRAWER_TO_CREATE);
  }
}
