
























































import {Vue, Component, Prop} from "vue-property-decorator";
import IReport from "@/interfaces/models/report.interface";
import * as actionTypes from "@/store/action-types";
import {Helpers} from "@/common/utils/helpers.js";

@Component
export default class ReportCard extends Vue {
  @Prop({type: Object, required: true}) report!: IReport;
  @Prop({type: Boolean, required: true}) selected!: boolean;
  @Prop({type: Boolean, required: true}) isSelectAll!: boolean;

  // openEditDrawer(): void {
  //   this.$store.dispatch(actionTypes.OPEN_DRAWER_TO_EDIT, {
  //     reportId: this.report.id,
  //   });
  // }

  renderTooltip(report: IReport) : string {
    let html = "";
    if(report.text) {
      html = report.text;
    }
    if(report.media) {
      html = `<img src="${report.media}" alt="textodog ${report.id}">`;
    }
    return html;
  }
}
