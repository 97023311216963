


















































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import * as actionTypes from "@/store/action-types";
import ITag from "@/interfaces/models/tag.interface";

@Component
export default class MobileSubFilterPopup extends Vue {
  @Prop({ type: Boolean }) isVisible!: Boolean;
  @Prop({ type: String, default: "firstName" }) filterType!: String;
  @Prop({ type: String, default: "First Name" }) title!: String;
  @Prop({ type: Array, default: [] }) keywords!: Array<String>;
  @Prop({ type: Array, default: () => [] }) tags!: ITag[];

  showSubFilterPopup: boolean = false;
  filter_isSubscribed: string = "";

  // Keyword area related variables
  filters: Array<String> = [""];

  mounted(): void {
    // this.$refs.filter_0.$el.focus();
  }
  cancel(): void {
    this.$emit("close-sub-mobile-filter-dialog", this.filterType);
  }

  @Watch("isVisible")
  onDialogShow() {
    if (this.isVisible) {
      setTimeout(() => {
        window.document.getElementById(this.filterType + "_filter_0")!.focus();
      }, 500);
    }
  }

  @Watch("keywords")
  onKeywordChanged(value: Array<String>, oldValue: Array<String>) {
    this.filters = [];

    if (value.length > 0) {
      value.forEach(val => {
        if (val !== "") this.filters.push(val);
      });
      // this.filters = value
    } else {
      this.filter_isSubscribed = "all";
      if (this.filters.length === 0) {
        this.filters.push("");
      }
    }
  }

  addFilter() {
    this.filters.push("");
  }

  clearFilter(index: any) {
    this.filters.splice(index, 1);
    if (this.filters.length === 0) {
      this.filters.push("");
    }
  }

  handleCloseDialog(event: KeyboardEvent): void {
    if (event.key === "Escape") {
      this.cancel();
    }
  }

  onFilterUpdate(): void {
    let filteredKeywords = this.filters.filter(f => f !== "" && f !== null);

    if (this.filterType === "isSubscribed" && this.filter_isSubscribed) {
      if (this.filter_isSubscribed === "all") {
        filteredKeywords = [];
      } else {
        filteredKeywords = [this.filter_isSubscribed];
      }
    }

    this.$emit("update-filter-sub", {
      filterType: this.filterType,
      keywords: filteredKeywords
    });
  }

  isTagSelected(t: string): boolean {
    const res = this.keywords.includes(t);
    return this.keywords.includes(t);
  }

  selectTag(value: boolean, tag: string): void {
    this.filters = this.filters.filter(k => k !== tag);
    if (value) {
      this.filters.push(tag);
    }
  }
}
