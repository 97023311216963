














































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import ContactsTable from "@/components/contacts/ContactsTable.vue";
import { Getter } from "vuex-class";
import IContact from "@/interfaces/models/contact.interface";
import AddTagPopup from "@/components/tags/AddTagPopup.vue";
import RemoveTagPopup from "@/components/tags/RemoveTagPopup.vue";
import DeleteContactPopup from "@/components/contacts/DeleteContactPopup.vue";
import EditContactDrawer from "@/components/contacts/EditContactDrawer.vue";
import ComposeMessagePopup from "@/components/inbox/ComposeMessagePopup.vue";
import ImportContactsPopup from "@/components/contacts/ImportContactsPopup.vue";
import * as actionTypes from "@/store/action-types";
import SearchPopup from "@/components/contacts/SearchPopup.vue";
import MobileFilterPopup from "@/components/contacts/MobileFilterPopup.vue";
import NavigationDrawer from "@/components/header/NavigationDrawer.vue";
import { NotificationType } from "@/interfaces/models/INotification";
import IContactFilter from "@/interfaces/models/contactFilter.interface";
import ITag from "@/interfaces/models/tag.interface";
import { ContactsService } from "@/http/services/contacts.service";
import { filter } from "vue/types/umd";
import Pusher from "pusher-js";
import { ENV } from "@/common/utils/env";

@Component({
  components: {
    NavigationDrawer,
    SearchPopup,
    MobileFilterPopup,
    ImportContactsPopup,
    ComposeMessagePopup,
    EditContactDrawer,
    DeleteContactPopup,
    RemoveTagPopup,
    AddTagPopup,
    ContactsTable
  }
})
export default class Contacts extends Vue {
  @Getter contacts!: IContact[];
  @Getter allContacts!: IContact[];
  @Getter tags!: ITag[];
  @Getter contacts_ct!: number;

  pusher: any;
  selectedContacts: number[] = [];
  fab: boolean = false;
  showAddTagDialog: boolean = false;
  showRemoveTagDialog: boolean = false;
  showRemoveContactDialog: boolean = false;
  showCompose: boolean = false;
  selectedComposeContacts: number[] = [];
  showImportPopup: boolean = false;
  showSearchPopup: boolean = false;
  showMobileFilterPopup: boolean = false;
  isLoading: boolean = false;
  isClearFilter: boolean = false;
  isSelectAll: boolean = false;
  searchParam: any = {};

  searchValue: string = "";

  /* filter variable */
  searchFilter: IContactFilter = {
    isSubscribed: { show: false, sort: 0, keywords: [] },
    firstName: { show: false, sort: 0, keywords: [] },
    lastName: { show: false, sort: 0, keywords: [] },
    phoneNumber: { show: false, sort: 0, keywords: [] },
    companyName: { show: false, sort: 0, keywords: [] },
    email: { show: false, sort: 0, keywords: [] },
    creationDate: { show: false, sort: 0, keywords: [] },
    tags: { show: false, sort: 0, keywords: [] }
  };
  customSearchFilter: any[] = [];
  existCustomFields: boolean = false;
  sortedContacts: IContact[] = [];
  totalContactPages: number = 0;
  curPage: number = 1;
  perPage: number = 10;
  contacts_count: number = 0;
  filteredParam: string = "";

  async mounted(): Promise<void> {
    if (!this.contacts.length) {
      await Promise.all([
        this.$store.dispatch(actionTypes.FETCH_TAGS),
        this.$store.dispatch(actionTypes.FETCH_CONTACTS)
      ]);
    }
    this.$store.dispatch(actionTypes.FETCH_ALL_CONTACTS);
    this.initPusher();
  }

  initPusher(): void {
    this.pusher = new Pusher(ENV.PUSHER_APP_KEY, {
      cluster: "us2"
    });
    const channel = this.pusher.subscribe("textodog-message");
    channel.bind("contactStatusUpdated", (data: any) => {
      // conversationId, messageId, status
      console.log("pusher data for contactStatusUpdated : ", data);
      const isExisting = this.sortedContacts.findIndex((iC: IContact) => iC.id === data.contactId);
      if (isExisting !== -1) {
        const sRs = [...this.sortedContacts];
        sRs[isExisting].subscribed = data.isSubscribed;
        this.sortedContacts = [...sRs];
      }
    });
  }

  @Watch("contacts", { immediate: true })
  setPageHeader(): void {
    this.contacts_count = Number(this.contacts_ct);
    this.totalContactPages = Math.ceil(this.contacts_count / this.perPage);
    let t_customSearchFilter = [];
    if (this.contacts.length > 0 && this.contacts[0].customFields.length > 0) {
      this.existCustomFields = true;
      for (let index = 0; index < this.contacts[0].customFields.length; index++) {
        const element: any = this.contacts[0].customFields[index];
        t_customSearchFilter.push({
          show: false,
          index,
          id: element.id,
          key: element.name,
          keywords: this.customSearchFilter.length ? this.customSearchFilter[index].keywords : []
        });
      }
    }
    if (t_customSearchFilter.length > 0) this.customSearchFilter = t_customSearchFilter;

    this.sortedContacts = [...this.contacts];
    this.$store.dispatch("header/updateHeaderTitle", `${this.contacts.length} Contacts`);
  }

  @Watch("contacts_ct")
  getWatchContactCt(): void {
    this.contacts_count = Number(this.contacts_ct);
  }

  selectAll(state: boolean): void {
    this.isSelectAll = state;
  }

  updatePagination(param: number) {
    this.curPage = param;
    this.getSearchContact();
  }

  async getSearchContact(): Promise<void> {
    this.searchParam = {};
    let filter_param: any = {};
    let orderBy = "";
    let direction = "";
    const sf: any = this.searchFilter;

    for (const key in this.searchFilter) {
      const pm: any = key;
      if (sf[pm].sort !== 0) {
        orderBy = pm;
        direction = sf[pm].sort === -1 ? "desc" : "asc";
      }
      if (key === "tags") {
        let filter_tags: any[] = [];
        this.tags.forEach(tag => {
          if (sf[pm].keywords.includes(tag.name)) filter_tags.push(tag.id);
        });
        if (filter_tags.length > 0) filter_param["tagIds"] = filter_tags.join();
      } else if (key === "phoneNumber") {
        if (sf[pm].keywords.length > 0) {
          if (sf[pm].keywords[0] !== "") filter_param[pm] = sf[pm].keywords[0].split("-").join("");
          // if(sf[pm].keywords[0] !== '') filter_param[pm] = sf[pm].keywords[0]
        }
      } else {
        if (sf[pm].keywords.length > 0) {
          if (sf[pm].keywords[0] !== "") filter_param[pm] = sf[pm].keywords.join();
          // if(sf[pm].keywords[0] !== '') filter_param[pm] = sf[pm].keywords[0]
        }
      }
    }
    let filter_cf: any[] = [];
    for (let index = 0; index < this.customSearchFilter.length; index++) {
      const element = this.customSearchFilter[index];

      if (element.keywords.length > 0) {
        if (element.keywords[0] !== "")
          filter_cf.push({ id: element.id, value: element.keywords.join() });
      }
    }
    if (filter_cf.length > 0) filter_param.customFields = JSON.stringify(filter_cf);
    if (orderBy !== "") {
      filter_param.orderBy = orderBy;
      filter_param.direction = direction;
    }
    // pagination
    filter_param["currentPage"] = this.curPage;
    filter_param["perPage"] = this.perPage;

    this.searchParam = filter_param;
    try {
      // this.sortedContacts = []
      this.isLoading = true;
      await this.$store.dispatch(actionTypes.FETCH_CONTACTS, filter_param);
      // const res_contacts = await ContactsService.fetchContacts(filter_param);
      this.onHideFilter();
      // this.sortedContacts = res_contacts.data;
      // this.contacts_count = Number(res_contacts.total);
      // this.totalContactPages = Math.ceil(this.contacts_count/this.perPage);
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Watch("selectedContacts", { immediate: true })
  observeCountOfSelected(): void {
    this.$store.dispatch("header/updateCountOfSelected", this.selectedContacts.length);
  }
  beforeDestroy(): void {
    this.$store.dispatch("header/updateCountOfSelected", 0);
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  async closeAddTag(): Promise<void> {
    await this.$nextTick();
    this.showAddTagDialog = false;
  }

  async addTag(tag: string): Promise<void> {
    try {
      this.isLoading = true;
      await this.$store.dispatch(actionTypes.ADD_TAG_FOR_MULTIPLE_CONTACTS, {
        contactIds: this.selectedContacts,
        tag
      });
      this.showAddTagDialog = false;
      await this.$store.dispatch(actionTypes.FETCH_TAGS);
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }

  async applyTagRemoving(tag: string): Promise<void> {
    try {
      this.isLoading = true;
      await this.$store.dispatch(actionTypes.REMOVE_TAG_FOR_MULTIPLE_CONTACTS, {
        contactIds: this.selectedContacts,
        tag
      });
      this.showRemoveTagDialog = false;
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }

  async applyContactDeleting(): Promise<void> {
    try {
      if (this.selectedContacts.length == 1) {
        await this.$store.dispatch(actionTypes.REMOVE_CONTACT, this.selectedContacts[0]);
      } else {
        await this.$store.dispatch(actionTypes.REMOVE_CONTACTS, this.selectedContacts);
      }
      this.showRemoveContactDialog = false;
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }

  importContacts(): void {
    this.showImportPopup = true;
  }

  createContact(): void {
    this.$store.dispatch(actionTypes.OPEN_DRAWER_TO_CREATE);
  }

  onClearFilter(): void {
    this.existCustomFields = false;
    const sf: any = this.searchFilter;
    for (const key in this.searchFilter) {
      const pm: any = key;
      sf[pm].show = false;
      sf[pm].sort = 0;
      sf[pm].keywords = [];
    }
    if (this.contacts.length > 0) {
      this.existCustomFields = true;
      for (let index = 0; index < this.customSearchFilter.length; index++) {
        const element = this.customSearchFilter[index];
        element.show = false;
        element.sort = 0;
        element.keywords = [];
      }
    }
    this.curPage = 1;
    this.getSearchContact();
    this.selectAll(false);
  }

  onHideFilter(): void {
    const sf: any = this.searchFilter;
    for (const key in this.searchFilter) {
      const pm: any = key;
      sf[pm].show = false;
    }
    if (this.contacts.length > 0) {
      for (let index = 0; index < this.customSearchFilter.length; index++) {
        const element = this.customSearchFilter[index];
        element.show = false;
      }
    }
  }

  resetSort(): void {
    const sf: any = this.searchFilter;
    for (const key in this.searchFilter) {
      const pm: any = key;
      sf[pm].sort = 0;
    }
  }

  updateSort(param: String): void {
    this.resetSort();
    const sf: any = this.searchFilter;
    const pm: any = param;
    sf[pm.sortType].sort = pm.sort;
    this.curPage = 1;
    this.getSearchContact();
  }
  updateCustomSort(param: Object): void {
    const pm: any = param;
    this.customSearchFilter = pm;
    this.curPage = 1;
    this.getSortContact();
  }
  getSortContact(): void {
    // values.sort((one, two) => (one > two ? -1 : 1));
    let sC: any[] = this.sortedContacts;
    const sf: any = this.searchFilter;
    for (const key in this.searchFilter) {
      const pm: any = key;
      if (sf[pm].sort === 1) {
        // Ascending
        sC.sort((a, b) => (a[pm] < b[pm] ? -1 : a[pm] > b[pm] ? 1 : 0));
      } else if (sf[pm].sort === -1) {
        // Descending
        sC.sort((a, b) => (a[pm] < b[pm] ? 1 : a[pm] > b[pm] ? -1 : 0));
      }
    }
    this.sortedContacts = sC;
  }
  getValidString(key: any, idx: number): string {
    if (key?.customFields) {
      if (key?.customFields[idx].value) {
        return key?.customFields[idx].value;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  updateFilter(param: Object): void {
    const pm: any = param;
    this.searchFilter = pm;
    this.curPage = 1;
    this.getSearchContact();
    // this.updateFilterContacts()
  }
  updateCustomFilter(param: Object): void {
    const pm: any = param;
    this.customSearchFilter = pm;
    this.curPage = 1;
    this.getSearchContact();
    // this.updateFilterContacts()
  }
  updateExistCustomField(param: boolean): void {
    this.existCustomFields = param;
    // if(!param) this.customSearchFilter = []
  }

  composeMessages(): void {
    let exportParam = { ...this.searchParam };
    exportParam["contactIds"] = this.selectedContacts;
    exportParam["selectedAll"] = this.isSelectAll;
    delete exportParam["perPage"];
    delete exportParam["currentPage"];
    this.filteredParam = JSON.stringify(exportParam);
    this.selectedComposeContacts = [...this.selectedContacts];
    this.showCompose = true;
  }

  async exportCSV(): Promise<void> {
    let csvParam = { ...this.searchParam };
    csvParam["contactIds"] = this.selectedContacts;
    csvParam["selectedAll"] = this.isSelectAll;
    delete csvParam["perPage"];
    delete csvParam["currentPage"];
    await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
      text: "CSV file will be sent shortly.",
      type: NotificationType.SUCCESS
    });
    try {
      this.isLoading = true;
      await ContactsService.exportContacts(csvParam);
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }

  sendToOneContact(contactId: number): void {
    this.selectedComposeContacts = [contactId];
    this.showCompose = true;
  }

  showAddTagPopup(): void {
    this.showAddTagDialog = true;
  }

  removeTag(): void {
    this.showRemoveTagDialog = true;
  }

  updateContacts(): void {
    console.log("update contacts");
  }

  exportContacts(): void {
    console.log("export contacts");
  }

  deleteContacts(): void {
    this.showRemoveContactDialog = true;
  }

  toggleSearchPopup(value: boolean, searchValue: string): void {
    this.showSearchPopup = value;
    this.searchValue = searchValue;
  }

  toggleMobileFilterPopup(value: boolean): void {
    this.showMobileFilterPopup = !this.showMobileFilterPopup;
  }

  // update filter contacts

  isMatching(originalStr: String, keys: Array<String>): Boolean {
    for (let index = 0; index < keys.length; index++) {
      const regex = new RegExp(`${keys[index]}`, "gi");
      const found = originalStr.match(regex);
      if (found) return true;
    }
    return false;
  }
  isMatchingTags(originalTags: ITag[], keys: Array<String>): Boolean {
    for (let index = 0; index < originalTags.length; index++) {
      if (keys.includes(originalTags[index].name)) return true;
    }
    return false;
  }

  typedKeys<T>(o: T): (keyof T)[] {
    // type cast should be safe because that's what really Object.keys() does
    return Object.keys(o) as (keyof T)[];
  }

  getIContactValueByKey(IC: IContact, field: String): String {
    let res_val: any;
    this.typedKeys(IC).forEach(key => {
      if (key == field) {
        res_val = IC[key];
      }
    });
    return res_val;
  }

  getFilterData(cts: IContact[], field: any = "firstName"): IContact[] {
    const t_sf: any = this.searchFilter;
    const arr_keywords: Array<String> = t_sf[field].keywords;
    if (arr_keywords.length > 0) {
      const filterData =
        field === "tags"
          ? cts.filter((t: any) => this.isMatchingTags(t[field], arr_keywords))
          : cts.filter((t: any) => this.isMatching(t[field], arr_keywords));
      // const filterData = cts.filter( t => this.isMatching(this.getIContactValueByKey(t, field), arr_keywords))
      return filterData;
    }
    return cts;
  }
  getCustomFilterData(cts: IContact[], field: any, arr_keywords: Array<String>): IContact[] {
    if (arr_keywords.length > 0) {
      const filterData = cts.filter((t: any) =>
        this.isMatching(t.customFields[field].value, arr_keywords)
      );
      return filterData;
    }
    return cts;
  }
  updateFilterContacts(): void {
    const sortedCtts = [...this.contacts];
    const t_firstName = this.getFilterData(sortedCtts, "firstName");
    const t_lastName = this.getFilterData(t_firstName, "lastName");
    const t_phoneNumber = this.getFilterData(t_lastName, "phoneNumber");
    const t_companyName = this.getFilterData(t_phoneNumber, "companyName");
    const t_email = this.getFilterData(t_companyName, "email");
    const t_tags = this.getFilterData(t_email, "tags");
    let customSortedCtts = [...t_tags];
    if (this.existCustomFields) {
      for (let index = 0; index < this.customSearchFilter.length; index++) {
        const element = this.customSearchFilter[index];
        customSortedCtts = this.getCustomFilterData(customSortedCtts, element.id, element.keywords);
      }
    }
    if (customSortedCtts.length === 0) {
      this.existCustomFields = false;
    } else {
      this.existCustomFields = true;
    }
    this.sortedContacts = customSortedCtts;
  }

  onClickPlus(): void {
    this.$store.dispatch(actionTypes.OPEN_DRAWER_TO_CREATE);
  }
}
