





















































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import IReport from "@/interfaces/models/report.interface";
import moment from "moment";
import ITag from "@/interfaces/models/tag.interface";
import IIdentifier from '@/interfaces/models/identifier.interface';

interface TagSelectCondition {
  id: string;
  name: string;
  icon: string;
  label: string;
}

@Component
export default class FilterPopup extends Vue {

  @Prop({type: Boolean}) isVisible!: Boolean;
  @Prop({type: String, default: 'fromNumber'}) filterType!: string;
  @Prop({type: Array, default: []}) keywords!: Array<string>;
  @Prop({type: Array, default: () =>[]}) tags!: ITag[];
  @Prop({type: Array, default: () =>[]}) identifiers!: IIdentifier[];
  currentTabIndex: number = 0;

  // validation
  rules: Object = ({
    required: (v: any) => !!v || 'this field is required'
  })

  // Report search related variables
  availableReports: IReport[] = [];
  reportSearchDebounceTimer: number = 0;
  isLoadingReports: boolean = false;

  // Keyword area related variables
  filters: Array<String> = [''];
  filter_date_0: string = '';
  filter_date_1: string = '';
  formValidation: Boolean = false;
  statusList: Array<String> = ['success', 'failure'];

  isLoading: Boolean = false;

  mounted(): void {
    if( this.keywords.length > 0 ) {
      this.keywords.forEach(val => {
        if(val !== '') this.filters.push(val)
      });
    }else {
      if(this.filters.length === 0) {
        this.filters.push('');
      }
    }
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  @Watch('keywords')
  onKeywordChanged(value: Array<String>, oldValue: Array<String>) {
    this.filters = []
    if( value.length > 0 ) {
      value.forEach((val: String) => {
        if(val !== '') this.filters.push(val)
      });
    }else {
      if(this.filters.length === 0) {
        this.filters.push('');
      }
    }
  }

  @Watch('isVisible')
  watchIsVisible() {
    if (this.isVisible) {
      document.addEventListener('keyup', this.handleCloseDialog);
      this.isLoading = false;
      setTimeout(() => {
        if(this.filterType !== 'status' && this.filterType !== 'tags' && this.filterType !== 'identifier')
          window.document.getElementById("filter_0")!.focus();
      }, 200);
      if(this.filterType === 'createdAt') {
        if(this.keywords.length > 0 && this.ruleDate(this.keywords[0]) && this.ruleToDate(this.keywords[1])) {
          this.formValidation = false;
        }else {
          this.formValidation = true;
        }
      }
    } else {
      document.removeEventListener('keyup', this.handleCloseDialog);
    }
  }

  async cancel(): Promise<void> {
    this.$emit('close-filter-dialog', this.filterType);
  }

  handleEsc(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.cancel();
    }
  }

  // createdAt
  nextFocus() : void {
    if(this.ruleDate(this.filter_date_0)) {
      setTimeout(() => {
        window.document.getElementById("filter_1")!.focus();
      }, 200);
    }
  }
  ruleDate(val: string): boolean {
    if( val && (val === '' || val.length < 10)) {
      return false;
    } else {
      return moment(val, "MM-DD-YYYY", true).isValid();
    }
  }
  modifyDate(str: string) : string {
    const str_arr = str.split("-");
    return str_arr[2] + "-" + str_arr[0] + "-" + str_arr[1]
  }
  ruleToDate(val: string): boolean {
    if(moment(val, "MM-DD-YYYY", true).isValid()) {
      if((this.filter_date_1 === this.filter_date_0) || moment(this.modifyDate(this.filter_date_1)).isAfter(this.modifyDate(this.filter_date_0))) {
        this.formValidation = false;
        return true;
      }else {
        return false;
      }

    }else {
      return false;
    }
  }

  // status
  isStatusSelected(t: string): boolean {
    return this.keywords.includes(t);
  }
  selectStatus(value: boolean, tag: string): void {
    this.filters = this.filters.filter(k => k !== tag)
    if(value) {
      this.filters.push(tag)
    }
  }
  renderStatus(status: string = '') : string {
    let html = "";
    let sts = status.toLowerCase();
    if(sts === 'pending' || sts === 'sent') sts = 'pending';
    html = `<p class='status-dot dot-${sts}' alt='${sts}'>&nbsp;</p>`;
    return html;
  }

  addFilter() {
    this.filters.push('');
  }

  clearFilter(index: any) {
    this.filters.splice(index, 1);
    if(this.filters.length === 0) {
      this.filters.push('')
    }
  }

  handleCloseDialog(event: KeyboardEvent): void {
    const e: any = event
    if (event.key === 'Escape') {
      this.cancel();
    } else if(event.key === 'Enter') {
      if(e.target.nodeName === 'INPUT') {
        this.onFilterUpdate()
      }
    }
  }

  onFilterUpdate(): void {
    let filteredKeywords = this.filters.filter( f => f !== '' && f !== null)
    if( this.filterType === 'createdAt' ){
      if( this.ruleDate(this.filter_date_0) && this.ruleToDate(this.filter_date_0)) {
        filteredKeywords = [this.filter_date_0, this.filter_date_1]
        this.formValidation = false
      }else {
        return ;
      }
    }
    this.isLoading = true;
    this.$emit('update-filter-sub', {
      filterType: this.filterType,
      keywords: filteredKeywords
    });
  }

  isTagSelected(t: string): boolean {
    const res = this.keywords.includes(t)
    return this.keywords.includes(t);
  }

  selectTag(value: boolean, tag: string): void {
    this.filters = this.filters.filter(k => k !== tag)
    if(value) {
      this.filters.push(tag)
    }
  }

  isIdentifierSelected(t: string): boolean {
    const res = this.keywords.includes(t)
    return this.keywords.includes(t);
  }

  selectIdentifier(value: boolean, identifier: string): void {
    this.filters = this.filters.filter(k => k !== identifier)
    if(value) {
      this.filters.push(identifier)
    }
  }

}
