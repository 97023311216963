






































































































































































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import * as actionTypes from "@/store/action-types";
import ITag from '@/interfaces/models/tag.interface';
import moment from "moment";
import IIdentifier from '@/interfaces/models/identifier.interface';

@Component
export default class MobileSubFilterPopup extends Vue {
  @Prop({type: Boolean}) isVisible!: Boolean;
  @Prop({type: String, default: 'firstName'}) filterType!: String;
  @Prop({type: String, default: 'First Name'}) title!: String;
  @Prop({type: Array, default: []}) keywords!: Array<string>;
  @Prop({type: Array, default: () =>[]}) tags!: ITag[];
  @Prop({type: Array, default: () =>[]}) identifiers!: IIdentifier[];

  showSubFilterPopup: boolean = false

  // Keyword area related variables
  filters: Array<String> = [''];
  filter_date_0: string = '';
  filter_date_1: string = '';
  formValidation: Boolean = false;
  statusList: Array<String> = ['success', 'failure'];


  // validation
  rules: Object = ({
    required: (v: any) => !!v || 'this field is required'
  })

  mounted(): void {
    // this.$refs.filter_0.$el.focus();
  }
  cancel(): void {
    this.$emit('close-sub-mobile-filter-dialog', this.filterType);
  }

  @Watch('isVisible')
  onDialogShow() {
    if(this.isVisible) {
      // window.document.getElementById(this.filterType + "_filter_0")!.focus();
      setTimeout(() => {
      if(this.filterType !== 'status' && this.filterType !== 'tags' && this.filterType !== 'identifier')
        window.document.getElementById(this.filterType + "_filter_0")!.focus();
      }, 200);
      if(this.filterType === 'createdAt') {
        if(this.keywords.length > 0) {
          this.filter_date_0 = this.keywords[0];
          this.filter_date_1 = this.keywords[1];
          this.formValidation = false;
        }else {
          this.formValidation = true;
        }
      }
    }
  }

  @Watch('keywords')
  onKeywordChanged(value: Array<String>, oldValue: Array<String>) {
    this.filters = []
    if( value.length > 0 ) {
      value.forEach(val => {
        if(val !== '') this.filters.push(val)
      });
    }else {
      if(this.filters.length === 0) {
        this.filters.push('');
      }
    }
  }

  // createdAt
  nextFocus() : void {
    if(this.ruleDate(this.filter_date_0)) {
      setTimeout(() => {
        window.document.getElementById("createdAt_filter_1")!.focus();
      }, 200);
    }
  }
  ruleDate(val: string): boolean {
    if( val === '' || val.length < 10) {
      return false;
    } else {
      if( moment(val, "MM-DD-YYYY", true).isValid() ) {
        return true
      }else {
        return false;
      }
    }
  }
  modifyDate(str: string) : string {
    const str_arr = str.split("-");
    return str_arr[2] + "-" + str_arr[0] + "-" + str_arr[1]
  }
  ruleToDate(val: string): boolean {
    if(moment(val, "MM-DD-YYYY", true).isValid()) {
      if((this.filter_date_1 === this.filter_date_0) || moment(this.modifyDate(this.filter_date_1)).isAfter(this.modifyDate(this.filter_date_0))) {
        this.formValidation = false;
        return true;
      }else {
        return false;
      }

    }else {
      return false;
    }
  }

  // status
  isStatusSelected(t: string): boolean {
    return this.keywords.includes(t);
  }
  selectStatus(value: boolean, tag: string): void {
    this.filters = this.filters.filter(k => k !== tag)
    if(value) {
      this.filters.push(tag)
    }
  }
  renderStatus(status: string = '') : string {
    let html = "";
    let sts = status.toLowerCase();
    if(sts === 'pending' || sts === 'sent') sts = 'pending';
    html = `<p class='status-dot dot-${sts}' alt='${sts}'>&nbsp;</p>`;
    return html;
  }

  addFilter() {
    this.filters.push('');
  }

  clearFilter(index: any) {
    this.filters.splice(index, 1);
    if(this.filters.length === 0) {
      this.filters.push('')
    }
  }

  handleCloseDialog(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.cancel();
    }
  }

  onFilterUpdate(): void {
    let filteredKeywords = this.filters.filter( f => f !== '' && f !== null)
    if( this.filterType === 'createdAt' ){
      if( this.ruleDate(this.filter_date_0) && this.ruleToDate(this.filter_date_0)) {
        filteredKeywords = [this.filter_date_0, this.filter_date_1]
        this.formValidation = false
      }else {
        return ;
      }
    }
    this.$emit('update-filter-sub', {
      filterType: this.filterType,
      keywords: filteredKeywords
    });
  }

  isTagSelected(t: string): boolean {
    const res = this.keywords.includes(t)
    return this.keywords.includes(t);
  }

  selectTag(value: boolean, tag: string): void {
    this.filters = this.filters.filter(k => k !== tag)
    if(value) {
      this.filters.push(tag)
    }
  }
}
