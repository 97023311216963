














































import {Vue, Component, Prop} from "vue-property-decorator";
import IContact from "@/interfaces/models/contact.interface";
import * as actionTypes from "@/store/action-types";
import {Helpers} from "@/common/utils/helpers.js";

@Component
export default class ContactCard extends Vue {
  @Prop({type: Object, required: true}) contact!: IContact;
  @Prop({type: Boolean, required: true}) selected!: boolean;
  @Prop({type: Boolean, required: true}) isSelectAll!: boolean;

  openEditDrawer(): void {
    this.$store.dispatch(actionTypes.OPEN_DRAWER_TO_EDIT, {
      contactId: this.contact.id,
    });
  }

  renderSubscribe(status: boolean = false) : string {
    let html = "";
    let sts = 'failure';
    if(status) sts = 'success';
    html = `<p class='status-dot dot-${sts}' alt='${sts}'>&nbsp;</p>`;
    return html;
  }


  get phoneNumber(): string {
    return Helpers.formatNumber(this.contact.phoneNumber);
  }
}
