








































































































































































































































































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import IReport from "@/interfaces/models/report.interface";
import IReportFilter from "@/interfaces/models/reportFilter.interface";
import ITag from "@/interfaces/models/tag.interface";
import IIdentifier from "@/interfaces/models/identifier.interface";
import ReportCard from "@/components/reports/ReportCard.vue";
import moment from "moment";
import * as actionTypes from "@/store/action-types";
import {Helpers} from "@/common/utils/helpers.js";
import FilterPopup from "@/components/reports/FilterPopup.vue";
import {Getter} from "vuex-class";

@Component({
  components: {
    ReportCard,
    FilterPopup
  }
})
export default class TableRow extends Vue {
  @Prop({type: Array, required: true}) sortedReports!: IReport[];
  @Prop({type: Number, required: true}) totalReportPages!: number;
  @Prop({type: Number, required: true}) totalReportCts!: number;
  @Prop({type: Number, required: true}) curPage!: number;
  @Prop({type: Number, required: true}) perPage!: number;
  // @Prop({type: Boolean, required: true}) isClearFilter!: Boolean;
  @Prop({type: Object, required: true}) searchFilter!: IReportFilter;
  @Prop({type: Array, required: true}) customSearchFilter!: any[];
  @Prop({type: Boolean, required: true}) existCustomFields!: Boolean;
  @Prop({type: Boolean, required: true}) isSelectAll!: boolean;
  selectedReports: Array<number | undefined> = [];
  selectedControl: boolean = false;
  currentPage: Number = 1;
  length: Number = 7;
  totalVisible: Number = 15;
  statusList: Array<String> = ['success', 'failure', 'pending', 'sent'];
  isAlphabeticalOrder: any = {};
  // sortedReports: IReport[] = [];
  @Getter tags!: ITag[];
  @Getter identifiers!: IIdentifier[];

  async mounted(): Promise<void> {
    // await this.$store.dispatch(actionTypes.FETCH_IDENTIFIER);
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  @Watch('curPage')
  watchCarPage(): void{
    this.currentPage = this.curPage;
  }

  changePagination(pg: number) : void {
    this.$emit('update-pagination', pg);
  }

  @Emit('send-message')
  sendMessage(reportId: number): number {
    return reportId;
  }

  renderTooltip(report: IReport) : string {
    let html = "";
    if(report.text) {
      html = report.text;
    }
    if(report.media) {
      html = `<img src="${report.media}" alt="textodog ${report.id}">`;
    }
    return html;
  }

  renderStatus(status: string = '') : string {
    let html = "";
    let sts = status.toLowerCase();
    if(sts === 'pending') sts = 'pending';
    if(sts === 'sent') sts = 'success';
    html = `<p class='status-dot dot-${sts}' alt='${sts}'>&nbsp;</p>`;
    return html;
  }
  showSortIcon(sortState: number) : string {
    if(sortState === 0) return 'mdi-menu-swap-outline'
    if(sortState === -1) return 'mdi-menu-up-outline'
    else return 'mdi-menu-down-outline'
  }

  updateSortState(curState: number) : number {
    if(curState === 0) return 1
    if(curState === 1) return -1
    else return 1
  }

  sortReports(sortType: string): void {
    if(sortType.indexOf('customField_') === -1) { // search filter
      const sf: any = this.searchFilter
      this.$emit('update-sort', {sortType, sort: this.updateSortState(sf[sortType].sort)})
    }else{ // custom search filter
      const cs = sortType.split('customField_')[1]
      const tmp = [...this.customSearchFilter]
      const cSF = tmp.find( k => k.key === cs)
      cSF.sort = this.updateSortState(cSF.sort)
      this.$emit('update-custom-sort', tmp)
    }
  }

  addFilterPopup(filterType: string, show: boolean): void {
    if(filterType.indexOf('customField_') === -1) {
      const sf: any = this.searchFilter
      sf[filterType].show = show
    }else{
      const cs = filterType.split('customField_')[1]
      const cSF = this.customSearchFilter.find( k => k.key === cs)
      cSF.show = show
    }
  }

  toggleFilterPopup(filterType: string): void {
    if(filterType.indexOf('customField_') === -1) {
      const sf: any = this.searchFilter
      // sf[filterType].keyword = searchValue
      sf[filterType].show = !sf[filterType].show
    }else{
      const cs = filterType.split('customField_')[1]
      const cSF = this.customSearchFilter.find( k => k.key === cs)
      cSF.show = !cSF.show
    }
  }

  updateFilterKeywords(param: Object): void {
    const pm: any = param
    if(pm.filterType.indexOf('customField_') === -1) {
      const sf: any = this.searchFilter
      // sf[pm.filterType].show = false
      sf[pm.filterType].keywords = pm.keywords
      this.$emit('update-filter', sf)
    }else{
      const cs = pm.filterType.split('customField_')[1]
      const tmp = [...this.customSearchFilter]
      const cSF = tmp.find( k => k.key === cs)
      // cSF.show = false
      cSF.keywords = pm.keywords
      this.$emit('update-custom-filter', tmp)
    }
  }

  // editUser(reportId: number): void {
  //   this.$store.dispatch(actionTypes.OPEN_DRAWER_TO_EDIT, {reportId});
  // }

  @Watch('isSelectAll')
  changedIsSelectAll(): void{
    if(this.isSelectAll) {
      this.selectAllUsers(true)
    }else {
      this.unSelectAllUsers()
    }
  }

  selectAllUsers(value: boolean): void {
    if (value) {
      this.selectedReports = this.sortedReports.map(({id}: IReport) => id);
    } else {
      this.selectedReports = [];
    }
    this.$emit('select-all', value);
  }

  unSelectAllUsers(): void {
    this.selectedReports = [];
  }

  disableCheckbox() : boolean {
    return this.isSelectAll;
  }

  isReportSelected(reportId: number): boolean {
    return this.isSelectAll || this.selectedReports.includes(reportId);
  }
  getValidString(key: any, idx: number) : string {
    if(key?.customFields && key?.customFields.length > 0) {
      if(key?.customFields[idx].value) {
        return key?.customFields[idx].value.join()
      }
      else {
        return ''
      }
    }else {
      return ''
    }
  }

  selectReport(value: boolean, reportId: number): void {
    if(value) {
      this.selectedReports = this.selectedReports.concat(reportId);
    } else {
      if(!this.isSelectAll) {
        this.selectedReports = this.selectedReports.filter(id => id !== reportId);
      }
    }
  }

  get countOfSelected(): number {
    const countOfSelected = this.selectedReports.length;
    this.$emit('change-selected-count', this.selectedReports);
    this.selectedControl = countOfSelected === this.totalReportCts && this.sortedReports.length > 0;

    return countOfSelected;
  }

  normalizeString(str: string): string {
    return `${str || ''}`.trim().toLowerCase();
  }

  emitAction(action: string): void {
    alert('coming soon')
    // this.$emit(action);
  }

  getFormattedDate(date: string): string {
    // return moment(date).format('LL HH:mm:ss');
    let html = moment(date).format('LL');
    html += '</br>' + moment(date).format('HH:mm:ss');
    return html;
  }

  getFormattedNumber(phoneNumber: string): string {
    return Helpers.formatNumber(phoneNumber);
  }

  truncatedName(name: string): string {
    return Helpers.truncateText(name, 8);
  }
}
