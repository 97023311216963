























































import {Vue, Component, Emit, Prop} from "vue-property-decorator";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import ImageDropArea from "@/common/reusable-components/ImageDropArea.vue";

@Component({
  components: {ImageDropArea, vue2Dropzone}
})
export default class ImportContactsPopup extends Vue {
  @Prop({type: Boolean, required: true}) showPopup!: boolean;
  dropzoneOptions = {
    url: 't',
    maxFilesize: 1,
    maxFiles: 2,
    acceptedFiles: 'image/*'
  }

  @Emit('close-popup')
  closePopup(): void {

  }

  importContacts(): void {
    this.closePopup();
  }

  removeFile(file: any[]): void {
    if (file.length === 2) {
      (this.$refs.dropZone as any).removeFile(file[0]);
    }
  }
}
