



































import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";

@Component
export default class DeleteContactPopup extends Vue {
  @Prop({ type: Boolean, required: true }) showDialog!: boolean;
  @Prop({ type: Number, required: true }) countOfSelected!: number;

  @Emit("close-dialog")
  closeDialog(): void {}

  @Emit("apply-removing")
  applyRemoving(): void {}

  @Watch("showDialog")
  showDialogWatcher(): void {
    if (this.showDialog) {
      document.addEventListener("keyup", this.handleCloseDialog);
    } else {
      document.removeEventListener("keyup", this.handleCloseDialog);
    }
  }

  handleCloseDialog(event: KeyboardEvent): void {
    if (event.key === "Escape") {
      this.closeDialog();
    }
  }
}
