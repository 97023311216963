






















































































































































































































































































































































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import IContact from "@/interfaces/models/contact.interface";
import IContactFilter from "@/interfaces/models/contactFilter.interface";
import ITag from "@/interfaces/models/tag.interface";
import ContactCard from "@/components/contacts/ContactCard.vue";
import moment from "moment";
import * as actionTypes from "@/store/action-types";
import { Helpers } from "@/common/utils/helpers.js";
import FilterPopup from "@/components/contacts/FilterPopup.vue";
import { Getter } from "vuex-class";

@Component({
  components: {
    ContactCard,
    FilterPopup
  }
})
export default class TableRow extends Vue {
  @Prop({ type: Array, required: true }) allContacts!: IContact[];
  @Prop({ type: Array, required: true }) sortedContacts!: IContact[];
  @Prop({ type: Number, required: true }) totalContactPages!: number;
  @Prop({ type: Number, required: true }) totalContactCts!: number;
  @Prop({ type: Number, required: true }) curPage!: number;
  @Prop({ type: Number, required: true }) perPage!: number;
  // @Prop({type: Boolean, required: true}) isClearFilter!: Boolean;
  @Prop({ type: Object, required: true }) searchFilter!: IContactFilter;
  @Prop({ type: Array, required: true }) customSearchFilter!: any[];
  @Prop({ type: Boolean, required: true }) existCustomFields!: Boolean;
  @Prop({ type: Boolean, required: true }) isSelectAll!: boolean;
  selectedContacts: Array<number | undefined> = [];
  selectedControl: boolean = false;
  currentPage: Number = 1;
  length: Number = 7;
  totalVisible: Number = 15;
  // sortCriteria: String = 'firstName';
  isAlphabeticalOrder: any = {};
  // sortedContacts: IContact[] = [];
  @Getter tags!: ITag[];

  async mounted(): Promise<void> {
    // await this.$store.dispatch(actionTypes.FETCH_TAGS);
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  @Watch("curPage")
  watchCarPage(): void {
    this.currentPage = this.curPage;
  }

  changePagination(pg: number): void {
    this.$emit("update-pagination", pg);
  }

  @Emit("send-message")
  sendMessage(contactId: number): number {
    return contactId;
  }

  directComposeMessage(id: number): void {
    // if (!this.isSelectAll && this.selectedContacts.length === 0) {
    this.$emit("send-message", id);
    // }
  }

  showSortIcon(sortState: number): string {
    if (sortState === 0) return "mdi-menu-swap-outline";
    if (sortState === -1) return "mdi-menu-up-outline";
    else return "mdi-menu-down-outline";
  }

  updateSortState(curState: number): number {
    if (curState === 0) return 1;
    if (curState === 1) return -1;
    else return 1;
  }

  sortContacts(sortType: string): void {
    if (sortType.indexOf("customField_") === -1) {
      // search filter
      const sf: any = this.searchFilter;
      this.$emit("update-sort", { sortType, sort: this.updateSortState(sf[sortType].sort) });
    } else {
      // custom search filter
      const cs = sortType.split("customField_")[1];
      const tmp = [...this.customSearchFilter];
      const cSF = tmp.find(k => k.key === cs);
      cSF.sort = this.updateSortState(cSF.sort);
      this.$emit("update-custom-sort", tmp);
    }
  }

  addFilterPopup(filterType: string, show: boolean): void {
    if (filterType.indexOf("customField_") === -1) {
      const sf: any = this.searchFilter;
      sf[filterType].show = show;
    } else {
      const cs = filterType.split("customField_")[1];
      const cSF = this.customSearchFilter.find(k => k.key === cs);
      cSF.show = show;
    }
  }

  toggleFilterPopup(filterType: string): void {
    if (filterType.indexOf("customField_") === -1) {
      const sf: any = this.searchFilter;
      sf[filterType].show = !sf[filterType].show;
    } else {
      const cs = filterType.split("customField_")[1];
      const cSF = this.customSearchFilter.find(k => k.key === cs);
      cSF.show = !cSF.show;
    }
  }

  updateFilterKeywords(param: Object): void {
    const pm: any = param;
    if (pm.filterType.indexOf("customField_") === -1) {
      const sf: any = this.searchFilter;
      // sf[pm.filterType].show = false
      sf[pm.filterType].keywords = pm.keywords;
      this.$emit("update-filter", sf);
    } else {
      const cs = pm.filterType.split("customField_")[1];
      const tmp = [...this.customSearchFilter];
      const cSF = tmp.find(k => k.key === cs);
      // cSF.show = false
      cSF.keywords = pm.keywords;
      this.$emit("update-custom-filter", tmp);
    }
  }

  editUser(contactId: number): void {
    this.$store.dispatch(actionTypes.OPEN_DRAWER_TO_EDIT, { contactId });
  }

  @Watch("isSelectAll")
  changedIsSelectAll(): void {
    if (this.isSelectAll) {
      this.selectAllUsers(true);
    } else {
      this.unSelectAllUsers();
    }
  }

  selectAllUsers(value: boolean): void {
    if (value) {
      this.selectedContacts = this.allContacts.map(({ id }: IContact) => id);
    } else {
      this.selectedContacts = [];
    }
    this.$emit("select-all", value);
  }

  unSelectAllUsers(): void {
    this.selectedContacts = [];
  }

  isContactSelected(contactId: number): boolean {
    return this.isSelectAll || this.selectedContacts.includes(contactId);
  }
  getValidString(key: any, idx: number): string {
    if (key?.customFields) {
      if (key?.customFields[idx].value) {
        return key?.customFields[idx].value.join();
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  renderSubscribe(status: boolean = false): string {
    let html = "";
    let sts = "failure";
    if (status) sts = "success";
    html = `<p class='status-dot dot-${sts}' alt='${sts}'>&nbsp;</p>`;
    return html;
  }

  selectContact(value: boolean, contactId: number): void {
    this.selectedContacts = this.selectedContacts.filter(id => id !== contactId);
    if (value) {
      this.selectedContacts = this.selectedContacts.concat(contactId);
    }
  }

  get countOfSelected(): number {
    const countOfSelected = this.selectedContacts.length;
    this.$emit("change-selected-count", this.selectedContacts);
    this.selectedControl =
      this.selectedContacts.length === this.totalContactCts && this.sortedContacts.length > 0;
    return countOfSelected;
  }

  normalizeString(str: string): string {
    return `${str || ""}`.trim().toLowerCase();
  }

  emitAction(action: string): void {
    this.$emit(action);
  }

  getFormattedDate(date: string): string {
    return moment(date).format("LL");
  }

  getFormattedNumber(phoneNumber: string): string {
    return Helpers.formatNumber(phoneNumber);
  }

  truncatedName(name: string): string {
    return Helpers.truncateText(name, 8);
  }
}
