


































































































import {Vue, Component, Emit, Prop, Watch} from "vue-property-decorator";
import {Getter} from "vuex-class";
import ITag from "@/interfaces/models/tag.interface";

@Component
export default class AddTagPopup extends Vue {
  @Getter tags!: ITag[];

  @Prop({type: Boolean, required: true}) showDialog!: boolean;
  @Prop({type: Boolean, default: false}) isLoading!: boolean;
  tagMode: string = 'existing';
  selectedTag: string = '';
  newTagValue: string = '';

  @Watch('showDialog')
  handleDialogVisibilityChange(): void {
    if (!this.showDialog) {
      this.selectedTag = '';
    }

    if (this.showDialog) {
      document.addEventListener('keyup', this.handleCloseDialog);
    } else {
      document.removeEventListener('keyup', this.handleCloseDialog);
    }
  }

  handleCloseDialog(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeDialog();
    }
  }

  @Emit('close-dialog')
  closeDialog(): void {
    this.selectedTag = '';
  }

  @Emit('add-tag')
  saveTags(): string {
    return this.tagMode === 'existing' ? this.selectedTag : this.newTagValue;
  }
}
